import { createRouter, createWebHistory} from 'vue-router'
import { apps } from '@/apps.js'

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    // home page
    { path: '/', name: 'home', component: () => import(/* webpackChunkName: "home" */ './views/HomeView.vue') }
  ]
  // apps
  .concat(apps.routes)
  .concat([
    // page-not-found
    { path: '/:pathMatch(.*)*', name: 'not-found', component: () => import(/* webpackChunkName: "page-not-found" */ './views/PageNotFoundView.vue') },
    { path: '/:pathMatch(.*)', name: 'bad-not-found', component: () => import(/* webpackChunkName: "page-not-found" */ './views/PageNotFoundView.vue') }
  ])
})

export default router
