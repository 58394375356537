
const r = {
  // shoti
  shoti: {
    name: 'shoti',
    path: '/shoti',
    altpath: (app) => `/${app}`,
    component: () => import(/* webpackChunkName: "shoti" */ './views/shoti/HomeView.vue')
  },
  shoti_terms: {
    name: 'shoti-terms',
    path: '/shoti/terms',
    altpath: (app) => `/${app}/terms`,
    component: () => import(/* webpackChunkName: "shoti-terms" */ './views/shoti/TermsView.vue')
  },
  shoti_privacy: {
    name: 'shoti-privacy',
    path: '/shoti/privacy',
    altpath: (app) => `/${app}/privacy`,
    component: () => import(/* webpackChunkName: "shoti-privacy" */ './views/shoti/PrivacyView.vue')
  },
  shoti_support: {
    name: 'shoti-support',
    path: '/shoti/support',
    altpath: (app) => `/${app}/support`,
    component: () => import(/* webpackChunkName: "shoti-support" */ './views/shoti/SupportView.vue')
  },

  // playme
  playme: {
    name: 'playme',
    path: '/playme',
    altpath: (app) => `/${app}`,
    component: () => import(/* webpackChunkName: "playme" */ './views/playme/HomeView.vue')
  },
  playme_terms: {
    name: 'playme-terms',
    path: '/playme/terms',
    altpath: (app) => `/${app}/terms`,
    component: () => import(/* webpackChunkName: "playme-terms" */ './views/playme/TermsView.vue')
  },
  playme_privacy: {
    name: 'playme-privacy',
    path: '/playme/privacy',
    altpath: (app) => `/${app}/privacy`,
    component: () => import(/* webpackChunkName: "playme-privacy" */ './views/playme/PrivacyView.vue')
  },
  playme_support: {
    name: 'playme-support',
    path: '/playme/support',
    altpath: (app) => `/${app}/support`,
    component: () => import(/* webpackChunkName: "playme-support" */ './views/playme/SupportView.vue')
  },

  // photo
  photo: {
    name: 'photo',
    path: '/photo',
    altpath: (app) => `/${app}`,
    component: () => import(/* webpackChunkName: "photo" */ './views/photo/HomeView.vue')
  },
  photo_terms: {
    name: 'photo-terms',
    path: '/photo/terms',
    altpath: (app) => `/${app}/terms`,
    component: () => import(/* webpackChunkName: "photo-terms" */ './views/photo/TermsView.vue')
  },
  photo_privacy: {
    name: 'photo-privacy',
    path: '/photo/privacy',
    altpath: (app) => `/${app}/privacy`,
    component: () => import(/* webpackChunkName: "photo-privacy" */ './views/photo/PrivacyView.vue')
  },
  photo_support: {
    name: 'photo-support',
    path: '/photo/support',
    altpath: (app) => `/${app}/support`,
    component: () => import(/* webpackChunkName: "photo-support" */ './views/photo/SupportView.vue')
  },

  // dryfire
  dryfire: {
    name: 'dryfire',
    path: '/dryfire',
    altpath: (app) => `/${app}`,
    component: () => import(/* webpackChunkName: "dryfire" */ './views/dryfire/HomeView.vue')
  },
  dryfire_terms: {
    name: 'dryfire-terms',
    path: '/dryfire/terms',
    altpath: (app) => `/${app}/terms`,
    component: () => import(/* webpackChunkName: "dryfire-terms" */ './views/dryfire/TermsView.vue')
  },
  dryfire_privacy: {
    name: 'dryfire-privacy',
    path: '/dryfire/privacy',
    altpath: (app) => `/${app}/privacy`,
    component: () => import(/* webpackChunkName: "dryfire-privacy" */ './views/dryfire/PrivacyView.vue')
  },
  dryfire_support: {
    name: 'dryfire-support',
    path: '/dryfire/support',
    altpath: (app) => `/${app}/support`,
    component: () => import(/* webpackChunkName: "dryfire-support" */ './views/dryfire/SupportView.vue')
  }
}

const routes = Object.values(r)

const appsArray = [
  // shoti
  {
    title: 'ShoTi',
    link: 'https://apps.apple.com/us/app/shoti-professional-shot-timer/id1127923532',
    route: r.shoti,
    subroutes: [
      { title: 'Overview', route: r.shoti },
      { title: 'Privacy', route: r.shoti_privacy },
      { title: 'Terms', route: r.shoti_terms },
      { title: 'Support', route: r.shoti_support }
    ]
  },
  // playme
  {
    title: 'PlayMe',
    link: 'https://apps.apple.com/us/app/playme-offline-media-player/id1480447941',
    route: r.playme,
    subroutes: [
      { title: 'Overview', route: r.playme },
      { title: 'Privacy', route: r.playme_privacy },
      { title: 'Terms', route: r.playme_terms },
      { title: 'Support', route: r.playme_support }
    ]
  },
  // photo
  {
    title: 'Dr. Photo',
    link: 'https://apps.apple.com/us/app/dr-photo-instant-camera/id1668959763',
    route: r.photo,
    subroutes: [
      { title: 'Overview', route: r.photo },
      { title: 'Privacy', route: r.photo_privacy },
      { title: 'Terms', route: r.photo_terms },
      { title: 'Support', route: r.photo_support }
    ]
  },
  // dryfire
  {
    title: 'Dry Fire TV',
    link: 'https://apps.apple.com/us/app/dry-fire-tv/id6447488737',
    route: r.dryfire,
    subroutes: [
      { title: 'Overview', route: r.dryfire },
      { title: 'Privacy', route: r.dryfire_privacy },
      { title: 'Terms', route: r.dryfire_terms },
      { title: 'Support', route: r.dryfire_support }
    ]
  }
]

function routesForApps() {
  return appsArray.map((app) => {
    return {
      title: app.title,
      route: app.route.path
    }
  });
}

function routesForPath(path) {
  if (path === undefined) {
    return routesForApps()
  }

  const route = routes.find(r => r.path === path)
  if (route === undefined) {
    return routesForApps()
  }

  return appsArray.map((app) => {
    return {
      title: app.title,
      route: route.altpath(app.route.name)
    }
  });
}

function subRoutesForPath(path) {
  const app = appsArray.find(
    a => a.subroutes.find(
      s => s.route.path === path
    ) !== undefined
  )

  if(app === undefined) {
    return []
  }

  return app.subroutes.map(r => ({
    title: r.title,
    route: r.route.path
  }))
}

function titleForPath(path) {
  const app = appsArray.find(
    a => a.subroutes.find(
      s => s.route.path === path
    ) !== undefined
  )

  if(app === undefined) {
    return 'Applications'
  }

  return app.title
}

export const apps = {
  routes: routes,
  titleForPath: (path) => titleForPath(path),
  routesForPath: (path) => routesForPath(path),
  subRoutesForPath: (path) => subRoutesForPath(path)
}

export default apps;
