const global = {
  developer: 'Alexander Balobanov',
  link: 'https://www.balobanov.com',

  shoti: 'ShoTi',
  shoti_download: 'https://apps.apple.com/us/app/shoti-professional-shot-timer/id1127923532',

  playme: 'PlayMe',
  playme_download: 'https://apps.apple.com/us/app/playme-offline-media-player/id1480447941',

  photo: 'Dr. Photo',
  photo_download: 'https://apps.apple.com/us/app/dr-photo-instant-camera/id1668959763',

  dryfire: 'Dry Fire TV',
  dryfire_download: 'https://apps.apple.com/us/app/dry-fire-tv/id6447488737'
}

export default global
