<template>
  <div id="app">
    <el-container>
      <!-- header -->
      <el-header>
        <!-- nav bar -->
        <el-menu :router="true" :default-active="$route.path" mode="horizontal">
          <!-- apps -->
          <el-menu-item index="/">
            <el-sub-menu index="/">
              <template #title>
                {{ apps.titleForPath($route.path) }}
              </template>
              <el-menu-item v-for="r in apps.routesForPath($route.path)" :key="r.title" :index="r.route">
                {{ r.title }}
              </el-menu-item>
            </el-sub-menu>
          </el-menu-item>
          <!-- app details -->
          <el-menu-item v-for="r in apps.subRoutesForPath($route.path)" :key="r.title" :index="r.route">
            {{ r.title }}
          </el-menu-item>
        </el-menu>
      </el-header>
      <!-- content -->
      <el-main>
        <el-row>
          <el-col
            :xs="{span: 24, offset: 0}"
            :sm="{span: 22, offset: 1}"
            :md="{span: 20, offset: 2}"
            :lg="{span: 16, offset: 4}"
            :xl="{span: 12, offset: 6}"
          >
            <!-- route outlet -->
            <router-view></router-view>
          </el-col>
        </el-row>
      </el-main>
      <!-- footer -->
      <el-footer>
        Copyright &copy; {{ currentYear }} — {{ developer }}. All rights reserved.
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import global from '@/global.js'
//import {apps, routes} from '@/apps.js'
import apps from '@/apps.js'
export default {
  name: 'app',
  computed: {
    currentYear: function () {
      return new Date().getFullYear()
    }
  },
  data() {
    return {
      developer: global.developer,
      link: global.link,
      apps: apps
    };
  },
  methods: {
  }
}
</script>

<style>
html, body {
  height: 100%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.el-menu-item * {
    vertical-align: middle;
    text-decoration: none;
}
.grecaptcha-badge {
  visibility: hidden;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
</style>
