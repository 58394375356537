import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import { ElLoading } from 'element-plus'
import 'element-plus/dist/index.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
//import VueVideoPlayer from '@videojs-player/vue'
//import 'video.js/dist/video-js.css'

const app = createApp(App)

app.use(ElementPlus, {})

app.use(VueReCaptcha, {
  siteKey: '6Lc5EMUUAAAAAJOT2QBu7WjGlrzQOibZiFN9_cSK',
  loaderOptions: {
    useRecaptchaNet: true
  }
})

app.use(router)

//app.use(VueVideoPlayer)

app.config.globalProperties.loading = ElLoading.service

app.mount('#app')
